<template>
  <div class="banner-item" @click="handleClick()">
    <BaseImg src="assets/images/components/mobile/pages/home/banner/jackpot/background.webp" :alt="item.name" />
    <div class="jackpot">
      <div class="jackpot__title">
        <BaseImg
          :src="`assets/images/components/mobile/pages/home/banner/jackpot/${item.partner_provider ?? 'hit'}.webp`"
          class
          :alt="item.name"
        />
        <span>{{ item.name }}</span>
      </div>
      <AnimateCountUp :key="item.jackpot" :number="item.jackpot" />
    </div>
  </div>
</template>

<script setup lang="ts">
import AnimateCountUp from '~/components/common/animate-count-up.vue'
defineProps({
  item: {
    type: Object,
    default: () => {}
  }
})

const emit = defineEmits(['click'])

const handleClick = () => {
  emit('click')
}
</script>

<style lang="scss" scoped src="public/assets/scss/components/mobile/pages/home/banner/banner-jackpot.scss" />
